import { getColorBySeverity } from "@/domain/vulnerability"
import cn from "classnames"
import { SeverityTagProps } from "."
import styles from "./SeverityTag.module.scss"

export const SeverityTag = ({
  className,
  severity,
  t,
  latter
}: SeverityTagProps) => {
  const text = t(`vulnerabilities.${severity.toLowerCase()}`)
  return (
    <span
      style={{ backgroundColor: getColorBySeverity(severity) }}
      className={cn(styles.container, latter && styles.latter, className)}
      data-tooltip-content={text}
    >
      <span>{text}</span>
    </span>
  )
}
