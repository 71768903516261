import cn from "classnames"

import { ExploitIconsGroup } from "../Icon"

import styles from "../Icon.module.scss"

import type { ExploitIconProps } from "."

export const ExploitIcon = ({
  type,
  background,
  className,
  ...props
}: ExploitIconProps) => {
  if (!type) return null

  const Component = ExploitIconsGroup[type]

  if (!Component) return null

  return (
    <Component
      className={cn(className, background && styles.withBackground)}
      {...props}
    />
  )
}
