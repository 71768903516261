import cn from "classnames"
import { Link as LinkReactRouter } from "react-router-dom"

import styles from "./Link.module.scss"

import { LinkProps } from "."

export const Link = (props: LinkProps) => {
  const { to, children, className } = props

  if (to) {
    return (
      <LinkReactRouter to={to} className={className}>
        {children}
      </LinkReactRouter>
    )
  }

  const { href, target, type } = props

  return (
    <a
      href={href}
      target={target}
      className={cn(className, { [styles.link]: type === "link" })}
    >
      {children}
    </a>
  )
}
