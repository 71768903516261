import cn from "classnames"
import { LanguageIconsGroup } from "../Icon"
import styles from "../Icon.module.scss"
import { LanguageProps } from "./Language.types"

export const getLanguageIcon = (language: Language) => {
  return LanguageIconsGroup[language] || LanguageIconsGroup.unknown
}

export const Language = ({
  className,
  secondary,
  noTooltip,
  language,
  withBg = true,
  ...props
}: LanguageProps) => {
  const value = language || "unknown"
  const Icon = getLanguageIcon(value)

  return (
    <Icon
      className={cn(
        styles.language,
        secondary && styles.secondary,
        withBg && styles.withBackground,
        className
      )}
      data-tooltip-content={noTooltip ? undefined : value}
      {...props}
    />
  )
}
