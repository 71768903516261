import { getKodemScoreColor, getScore } from "@/domain/score"
import type { ScoreBadgeProps } from "."

export const ScoreBadge = ({ score, ghost }: ScoreBadgeProps) => {
  const background = getKodemScoreColor(ghost ? undefined : score)
  let x = "15"

  if (score) {
    x = score >= 10 ? "13" : "17"
  }

  return (
    <svg
      width="45"
      height="22"
      viewBox="0 0 45 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.89543 0.895431 0 2 0H11V22H2C0.895431 22 0 21.1046 0 20V2Z"
        fill="#8C8C8C"
      />
      <path
        d="M9 12.4415L6.201 14.8625L9 14.8625L9 15.8885L2.745 15.8885L2.745 14.8625L5.598 14.8625L2.745 12.4325L2.745 11.1455L5.877 13.8635L9 11.1005L9 12.4415ZM9.063 8.13216C9.063 8.55216 8.991 8.93016 8.847 9.26616C8.697 9.60216 8.487 9.86616 8.217 10.0582C7.947 10.2502 7.632 10.3462 7.272 10.3462L7.272 9.24816C7.542 9.22416 7.764 9.11916 7.938 8.93316C8.112 8.74116 8.199 8.47416 8.199 8.13216C8.199 7.77816 8.115 7.50216 7.947 7.30416C7.773 7.10616 7.551 7.00716 7.281 7.00716C7.071 7.00716 6.9 7.07016 6.768 7.19616C6.636 7.31616 6.534 7.46916 6.462 7.65516C6.39 7.83516 6.312 8.08716 6.228 8.41116C6.12 8.81916 6.012 9.15216 5.904 9.41016C5.79 9.66216 5.616 9.87816 5.382 10.0582C5.148 10.2382 4.836 10.3282 4.446 10.3282C4.086 10.3282 3.771 10.2382 3.501 10.0582C3.231 9.87816 3.024 9.62616 2.88 9.30216C2.736 8.97816 2.664 8.60316 2.664 8.17716C2.664 7.57116 2.817 7.07616 3.123 6.69216C3.423 6.30216 3.837 6.08616 4.365 6.04416L4.365 7.17816C4.137 7.19616 3.942 7.30416 3.78 7.50216C3.618 7.70016 3.537 7.96116 3.537 8.28516C3.537 8.57916 3.612 8.81916 3.762 9.00516C3.912 9.19116 4.128 9.28416 4.41 9.28416C4.602 9.28416 4.761 9.22716 4.887 9.11316C5.007 8.99316 5.103 8.84316 5.175 8.66316C5.247 8.48316 5.325 8.23716 5.409 7.92516C5.523 7.51116 5.637 7.17516 5.751 6.91716C5.865 6.65316 6.042 6.43116 6.282 6.25116C6.516 6.06516 6.831 5.97216 7.227 5.97216C7.545 5.97216 7.845 6.05916 8.127 6.23316C8.409 6.40116 8.637 6.65016 8.811 6.98016C8.979 7.30416 9.063 7.68816 9.063 8.13216Z"
        fill="white"
      />
      <path
        d="M11 0H43C44.1046 0 45 0.895431 45 2V20C45 21.1046 44.1046 22 43 22H11V0Z"
        fill={background}
      />
      <text y="16" x={x} fill="white">
        {getScore(score)}
      </text>
    </svg>
  )
}
