import cn from "classnames"

import { BitbucketDark, GitHub } from ".."
import styles from "../Icon.module.scss"
import type { SourceControlIconProps } from "./SourceControl.types"

export const SourceControlIcon = ({
  sourceControl,
  className,
  padding,
  ...props
}: SourceControlIconProps) => {
  const Icon = sourceControl === "GitHub" ? GitHub : BitbucketDark

  return (
    <Icon
      data-tooltip-content={sourceControl}
      className={cn(
        styles.withBackground,
        padding && styles.withPadding,
        className
      )}
      {...props}
    />
  )
}
