import { Fragment } from "react"

import { Popover } from "@/atoms"
import { getColorBySeverity } from "@/domain/issue"
import { BugOutlined, CodeWeakness, IconWithFrame, Mal } from "@/icons"
import { SeverityTag } from "@/molecules/SeverityTag"

import styles from "./IssueTypeIcon.module.scss"

import type { IssueTypeIconComponent } from "."

const IssueIconsByType = {
  Vulnerable_Package: BugOutlined,
  Malicious_Package: Mal,
  Vulnerable_Code: CodeWeakness
}

export const IssueTypeIcon: IssueTypeIconComponent = ({
  type,
  popover,
  severity,
  t,
  ...rest
}) => {
  const style = severity
    ? {
        backgroundColor: getColorBySeverity(severity),
        color: "var(--neutral-1-color)"
      }
    : undefined
  const props = { style, ...rest }
  const Icon = IssueIconsByType[type]
  const Wrap = popover ? Popover : Fragment
  const showPopover = !!popover && !!t

  if (severity) {
    const wrapProps = showPopover
      ? {
          content: (
            <div className={styles.popover}>
              <div>
                <span className={styles.label}>{t("general.type")}</span>
                <span>{t(`issueTypes.${type}`)}</span>
              </div>
              <div>
                <span className={styles.label}>{t("general.severity")}</span>
                <SeverityTag t={t} severity={severity} />
              </div>
            </div>
          )
        }
      : {}
    return <Wrap {...wrapProps}>{IconWithFrame(Icon, props)}</Wrap>
  }

  const { size, ...restProps } = props

  return <Icon {...restProps} />
}
